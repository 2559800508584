import {
    useSWRPaginationTemplate,
    useSWRTemplate,
} from 'hooks/dataHooksTemplate';
import { useAuth } from 'hooks/useAuth';
import { get, sortBy, reverse, orderBy, flatten, keys, result } from 'lodash';
import { cleanObjectNilValue } from 'utils/clean';
import { api } from './common';
import { processClosedOrders, processForexAccountList } from 'data/forex';
import { formatedStartEndDate, processStartEndDateLocale } from 'utils/time';
import {
    formatMonthDataAndYear,
    formatDateInTimezone,
    getLocalTime,
} from 'utils/formator';
import client, { adminClient } from 'helper/httpClient';
import { addYears } from 'date-fns';
import { processCardType, processDraftData, processCardData } from 'data/debit'

export const useDebitCards = (options = {}) => {
    const { search, cardNum, status, region, type, startDate, endDate } = options;
    const swr = useSWRPaginationTemplate({
        key: [
            'debit-card-list',
            search,
            cardNum,
            status,
            region,
            type,
            startDate,
            endDate,
        ],
        request: async (
            [
                _,
                _search,
                _cardNum,
                _status,
                _region,
                _type,
                _startDate,
                _endDate,
            ],
            _pageIndex,
            _pageSize,
        ) => {
            const { result } = await api().get(
                ['dtps', 'cards'],
                cleanObjectNilValue({
                    search: _search,
                    cardNum: _cardNum,
                    status: _status,
                    region: _region,
                    type: _type,
                    startDate: _startDate
                        ? formatMonthDataAndYear(_startDate)
                        : null,
                    endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize,
                }),
            );

            // const result = {
            //     items: new Array(10).fill(0).map((item) => {
            //         return {
            //             reference: (Math.random() * 10000000000).toFixed(0),
            //             createdAt: new Date().toISOString(),
            //             clientDetail: {
            //                 id: 10270,
            //                 name: 'OX S',
            //             },
            //             region: 'US',
            //             cardNum: (Math.random() * 10000000000).toFixed(0),
            //             type: 'silverMetal',
            //             status: 'mailed',
            //             updatedAt: new Date().toISOString(),
            //             balance: 0,
            //             expireDate: addYears(new Date(), 1),
            //         };
            //     }),
            //     totalItems: 10,
            // };

            return {
                result: {
                    items: get(result, 'items').map(item => {
                        return {
                            ...item,
                            clientDetail: {
                                id: get(item, ':user.id'),
                                name: get(item, ':user.name')
                            }
                        }
                    }),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: { items: [], totalItems: 0 },
    });
    return {
        ...swr,
    };
};

export const useDebitCardDetail = ({ id }) => {
    const swr = useSWRTemplate({
        key: id ? ['debit-card-detail', id] : id,
        request: async ([_, _id]) => {
            // const {result} = await api().get(['debit-card', _id])
            const result = {
                reference: (Math.random() * 10000000000).toFixed(0),
                createdAt: new Date().toISOString(),
                clientDetail: {
                    id: 10270,
                    name: 'OX S',
                },
                region: 'US',
                cardNum: (Math.random() * 10000000000).toFixed(0),
                type: 'silverMetal',
                status: 'mailed',
                updatedAt: new Date().toISOString(),
                balance: 0,
                expireDate: addYears(new Date(), 1),
            };
            return result;
        },
    });
    return {
        ...swr,
    };
};


export const useDebitRequest = (options = {}) => {
    const { search, status, startDate, endDate } = options;
    const swr = useSWRPaginationTemplate({
        key: [
            'debit-card-list',
            search,
            status,
            startDate,
            endDate,
        ],
        request: async (
            [
                _,
                _search,
                _status,
                _startDate,
                _endDate,
            ],
            _pageIndex,
            _pageSize,
        ) => {
            const { result } = await api().get(
                ['dtps', 'applications'],
                cleanObjectNilValue({
                    search: _search,
                    status: _status,
                    startDate: _startDate
                        ? formatMonthDataAndYear(_startDate)
                        : null,
                    endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize,
                }),
            );
            // const {result} = await api().get(['dtps', 'application'], cleanObjectNilValue({
            //     client: _search,
            //     status
            // }))

            // const result = {
            //     items: new Array(10).fill(0).map((item) => {
            //         return {
            //             createdAt: new Date().toISOString(),
            //             clientDetail: {
            //                 id: 10270,
            //                 name: 'OX S',
            //             },
            //             region: 'US',
            //             email: 'test@bull.com',
            //             status: 'draft',
            //         };
            //     }),
            //     totalItems: 10,
            // };

            return {
                result: {
                    items: get(result, 'items').map(item => {
                        return {
                            ...item,
                            clientDetail: {
                                id: get(item, ':user.id'),
                                name: get(item, ':user.name')
                            },
                            status: get(item, ':cardUser.status')
                        }
                    }),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: { items: [], totalItems: 0 },
    });
    return {
        ...swr,
    };
};

export const useDebitCardsFeeAndTopUps = (options = {}) => {
    const { search, status, startDate, endDate, user, region } = options;
    const swr = useSWRPaginationTemplate({
        key: [
            'debit-card-topups',
            search,
            status,
            user,
            startDate,
            endDate,
            region
        ],
        request: async (
            [
                _,
                _search,
                _status,
                _user,
                _startDate,
                _endDate,
                _region
            ],
            _pageIndex,
            _pageSize,
        ) => {
            const { result } = await api().get(
                ['dtps', 'fee-topup'],
                cleanObjectNilValue({
                    search: _search,
                    status: _status,
                    user: _user,
                    startDate: _startDate
                        ? formatMonthDataAndYear(_startDate)
                        : null,
                    endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                    region: _region,
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize,
                }),
            );

            // const result = {
            //     items: new Array(10).fill(0).map((item) => {
            //         return {
            //             date: new Date().toISOString(),
            //             clientDetail: {
            //                 id: 10270,
            //                 name: 'OX S',
            //             },
            //             region: 'US',
            //             fromAccount: '7000001',
            //             currency: 'USD',
            //             amount: Math.random() * 10000,
            //             notes: 'test',
            //             status: 'pending'
            //         };
            //     }),
            //     totalItems: 10,
            // };

            return {
                result: {
                    items: get(result, 'items').map(item => {
                        return {
                            ...item,
                            clientDetail: {
                                id: get(item, ':user.id'),
                                name: get(item, ':user.name')
                            },
                        }
                    }),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: { items: [], totalItems: 0 },
    });
    return {
        ...swr,
    };
};

export const useDebitCardsTransaction = (options = {}) => {
    const { search, status, startDate, endDate } = options;
    const swr = useSWRPaginationTemplate({
        key: [
            'debit-card-transaction',
            search,
            status,
            startDate,
            endDate,
        ],
        request: async (
            [
                _,
                _search,
                _status,
                _startDate,
                _endDate,
            ],
            _pageIndex,
            _pageSize,
        ) => {
            const {result} = await api().get(
                ['dtps', 'card-transaction'],
                cleanObjectNilValue({
                    search: _search,
                    status: _status,
                    startDate: _startDate
                        ? formatMonthDataAndYear(_startDate)
                        : null,
                    endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize,
                }),
            );

            // const result = {
            //     items: new Array(10).fill(0).map((item) => {
            //         return {
            //             updatedAt: new Date().toISOString(),
            //             ticket: (Math.random() * 1000000000).toFixed(0),
            //             clientDetail: {
            //                 id: 10270,
            //                 name: 'OX S',
            //             },
            //             region: 'US',
            //             fromSource: 'MT4',
            //             fromAccount: '7000001',
            //             fromAmount: Math.random() * 10000,
            //             toAccount: 'Debit 1',
            //             toAmount: Math.random() * 10000,
            //             currency: 'USD',
            //             notes: 'test',
            //             status: 'pending'
            //         };
            //     }),
            //     totalItems: 10,
            // };

            return {
                result: {
                    items: get(result, 'items').map(item=>{
                        return {
                            ...item,
                            clientDetail: {
                                id: get(item, ':user.id'),
                                name: get(item, ':user.name')
                            },
                            status: 'completed'
                        }
                    }),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: { items: [], totalItems: 0 },
    });
    return {
        ...swr,
    };
};


export const useDebitCardType = () => {
    const swr = useSWRTemplate({
        key: ['setting/debit-card-type'],
        request: async () => {
            const { result } = await api().get(['setting', 'card-type'])
            return result
        },
        defaultValue: {}
    })
    return {
        ...swr
    }
}

export const updateDebitType = async (id, data) => {
    const { result } = await api().put(['setting', 'card-type', id], data)
    return result
}

export const useDebitAutoApproval = () => {
    const swr = useSWRTemplate({
        key: ['setting/debit-card-auto-approval'],
        request: async () => {
            const { result } = await api().get(['setting', 'debit-card-auto-approval'])
            return result
        },
        defaultValue: {}
    })
    return {
        ...swr
    }
}

export const updateDebitAutoApproval = async (id, data) => {
    const { result } = await api().get(['setting', 'debit-card-auto-approval', id], data)
    return result
}

export const useClientDebitCardType = (user) => {
    const { isUser } = useAuth()
    const swr = useSWRTemplate({
        key: ['dtps/card-type'],
        request: async () => {
            const { result } = await api().get(['dtps', 'card-type'], { ...(isUser ? {} : { user }) })
            const cardOrder = ['Black Plastic', 'Silver Metal', 'Gold Metal', 'Black Metal'];
            const sortedResult = result.sort((a, b) => {
                return cardOrder.indexOf(a.code) - cardOrder.indexOf(b.code);
            });
            return sortedResult.map(processCardType)
            
        },
        defaultValue: {}
    })
    return {
        ...swr
    }
}

export const useGetUserCardInfo = (options = {}) => {
    const { sync, userId } = options;
    const swr = useSWRTemplate({
        key: userId? ['dtps/cards', sync, userId]: null,
        request: async ([_, _sync]) => {
            const { result } = await api().get(['dtps', 'cards'], cleanObjectNilValue({ userId }));
            return processCardData(result)
        },
        defaultValue: {}
    });
    return {
        ...swr,
    };
}

export const useUserCards = () => {
    const swr = useSWRTemplate({
        key: ['dtps/cardList'],
        request: async () => {
            const {result} = await api().get(['dtps', 'cards'])
            return result
        },
        defaultValue: []
    })
    return {
        ...swr
    }
}

export const useGetUserCardTransactions = (options = {}) => {
    const { id, status, startDate, endDate, user } = options;
    const { isUser } = useAuth()
    const swr = useSWRPaginationTemplate({
        key: ['dtps/card-transaction', id, status, startDate, endDate, user],
        request: async ([_, _id, _status, _startDate, _endDate, _user], _pageIndex, _pageSize) => {
            const {result} = await api().get(
                ['dtps','card-transaction'],
                cleanObjectNilValue({
                    status: _status,
                    startDate: _startDate
                        ? formatMonthDataAndYear(_startDate)
                        : null,
                    endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                    user: _user,
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize,
                }),
            );
            return {
                result: {
                    items: get(result, 'items').map(item => {
                        return {
                            ...item,
                            status: 'completed'
                        }
                    }),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: { items: [], totalItems: 0 }
    });
    return {
        ...swr,
    };
}

export const useGetUserCardTopup = (options = {}) => {
    const { cardUserId } = options;
    const swr = useSWRTemplate({
        key: ['dtps/topup', cardUserId],
        request: async ([_, _cardUserId,]) => {
            // const {result} = await api().get(
            //     ['debit-card'],
            //     cleanObjectNilValue({
            //         search: _search,
            //         status: _status,
            //         startDate: _startDate
            //             ? formatMonthDataAndYear(_startDate)
            //             : null,
            //         endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
            //         pageIndex: _pageIndex - 1,
            //         pageSize: _pageSize,
            //     }),
            // );
            const result = []
            return result
        },
        defaultValue: {}
    });
    return {
        ...swr,
    };
}

export const startApply = async (options = {}) => {
    const {
        terms,
        cardType
    } = options;
    const { result } = await api().post(
        ['dtps', 'info', 'start'],
        cleanObjectNilValue({
            terms: terms,
            cardType: cardType
        }),
    );
    return result;
};

export const postUserBaseInfo = async (options) => {
    const {
        birthCountry,
        cardDeliveryAddress,
        cardholder,
        deductionAccount,
        district,
        dob,
        email,
        embossName,
        gender,
        isdCode,
        occupation,
        placeOfBirth,
        province,
        telephone,
        title,
        village
    } = options;
    const { result } = await api().post(
        ['dtps', 'info', 'base-info'],
        cleanObjectNilValue({
            email: email,
            firstName: cardholder.split(' ')[0],
            lastName: cardholder.split(' ')[1],
            phoneCode: isdCode,
            phoneNo: telephone,
            title: title,
            occupation: occupation,
            gender: gender,
            dob: dob,
            birthCountry: birthCountry,
            birthPlace: placeOfBirth,
            province: province,
            city: district,
            village: village,
            terms: true,
            complaintsProcedures: true,
            privacyPolicy: true,
            deductionAccount: deductionAccount,
        }),
    );
    return result;
};

export const postUserPassportInfo = async (options) => {
    const {
        cardholder,
        deductionAccount,
        debitCardline1,
        debitCardSuburb,
        debitCardState,
        debitCardCountry,
        debitCardPostcode,
        dob,
        email,
        embossName,
        gender,
        isdCode,
        occupation,
        placeOfBirth,
        telephone,
        title,
        passportIssueDate,
        passportExpiryDate,
        passportNumber,
        country,
        signature,
        selfieHoldingPassport,
        passport
    } = options;
    const { result } = await api().post(
        ['dtps', 'info', 'passport'],
        {
            base: cleanObjectNilValue({
                email: email,
                firstName: cardholder.split(' ')[0],
                lastName: cardholder.split(' ')[1],
                phoneCode: isdCode,
                phoneNo: telephone,
                title: title,
                occupation: occupation,
                gender: gender ? gender : null,
                dob: dob ? dob : null,
                birthCountry: debitCardCountry ? debitCardCountry : null,
                birthPlace: placeOfBirth ? placeOfBirth : null,
                province: debitCardState ? debitCardState : null,
                city: debitCardSuburb ? debitCardSuburb : null,
                village: debitCardline1 ? debitCardline1 : null,
                complaintsProcedures: true,
                privacyPolicy: true,
                deductionAccount: deductionAccount ? deductionAccount : null,
                cardDeliveryAddress: (debitCardPostcode && debitCardline1 && debitCardSuburb && debitCardState && debitCardCountry) ? `${debitCardPostcode} ${debitCardline1} ${debitCardSuburb} ${debitCardState} ${debitCardCountry}` : null,
            }),
            passport: cleanObjectNilValue({
                passportIssue: passportIssueDate ? formatMonthDataAndYear(passportIssueDate) : null,
                passportExpiry: passportExpiryDate ? formatMonthDataAndYear(passportExpiryDate) : null,
                passportNumber: (country && passportNumber) ? `${country} ${passportNumber}` : null,
                signature: signature ? signature : null,
                selfiePassport: selfieHoldingPassport ? selfieHoldingPassport : null,
                passport: passport ? passport : null,
                embossname: embossName ? embossName : null,
                postcode: debitCardPostcode ? debitCardPostcode : null,
            })
        },
    );
    return result;
};

export const applyDebitCard = async (options) => {
    const {
        cardholder,
        deductionAccount,
        debitCardline1,
        debitCardSuburb,
        debitCardState,
        debitCardPostcode,
        debitCardCountry,
        dob,
        email,
        embossName,
        gender,
        isdCode,
        occupation,
        placeOfBirth,
        telephone,
        title,
        passportIssueDate,
        passportExpiryDate,
        passportNumber,
        country,
        signature,
        selfieHoldingPassport,
        passport,
        issueId,
        code,
        method
    } = options;
    const { result } = await api().post(
        ['dtps', 'create'],
        {
            issueId: issueId,
            code: code,
            method: method,
            base: cleanObjectNilValue({
                email: email,
                firstName: cardholder.split(' ')[0],
                lastName: cardholder.split(' ')[1],
                phoneCode: isdCode,
                phoneNo: telephone,
                title: title,
                occupation: occupation,
                gender: gender,
                dob: dob,
                birthCountry: debitCardCountry,
                birthPlace: placeOfBirth,
                province: debitCardState,
                city: debitCardSuburb,
                village: debitCardline1,
                complaintsProcedures: true,
                privacyPolicy: true,
                deductionAccount: deductionAccount ? deductionAccount : null,
                carddeliveryaddress: `${debitCardPostcode} ${debitCardline1} ${debitCardSuburb} ${debitCardState} ${debitCardCountry}`,
            }),
            passport: cleanObjectNilValue({
                passportExpiry: formatMonthDataAndYear(passportExpiryDate),
                passportIssue: formatMonthDataAndYear(passportIssueDate),
                passportNumber: `${country} ${passportNumber}`,
                signature: signature,
                selfiePassport: selfieHoldingPassport,
                passport: passport,
                embossname: embossName,
                postcode: debitCardPostcode,
            })
        },
    );
    return result;
};


export const withdrawDebitCard = async (options) => {
    const {
        issueId,
        code,
        method
    } = options;
    const { result } = await api().post(
        ['dtps', 'cancel-apply'],
        cleanObjectNilValue({
            issueId: issueId,
            code: code,
            method: method,
        }),
    );
    return result;
};

export const postUserSelfie = async (options) => {
    const {
        selfieHoldingCard,
        issueId,
        code,
        method
    } = options;
    const { result } = await api().post(
        ['dtps', 'avtive'],
        cleanObjectNilValue({
            selfieImg: selfieHoldingCard,
            issueId: issueId,
            code: code,
            method: method,
        }),
    );
    return result;
}

export const useGetApplyDetail = (id) => {
    const { isUser } = useAuth()
    const swr = useSWRTemplate({
        key: isUser ? ['dtps/detail/id', id] : null,
        request: async ([_, _id]) => {
            const { result } = await api().get(['dtps', 'detail', _id])
            return processDraftData(result)
        },
        defaultValue: {}
    })
    return {
        ...swr
    }
}

export const useAdminApplyDetail = (id) => {
    const swr = useSWRTemplate({
        key: id ? ['dtps/detail/id', id] : null,
        request: async ([_, _id]) => {
            const { result } = await api().get(['user', 'dtps-detail', _id])
            return processDraftData(result)
        },
        defaultValue: {}
    })
    return {
        ...swr
    }
}

export const updateDebitDetail = async (data, id) => {
    const { result } = await adminClient.put(['user', 'dtps-detail', id], cleanObjectNilValue(data))
    return result
}