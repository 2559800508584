import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {formatDate, currencyFormatorOfficial} from 'utils/formator';
import {get} from 'lodash';
import CustomTable from 'components/CustomTable';
import {Box, Text} from '@chakra-ui/react';
import {usePreference} from '../../hooks/usePreference';
import numeral from 'numeral';
import MT45TimeTableCell from 'components/TableCell/MT45TimeTableCell';


export default (props) => {
    const {
        title,
        data = [],
        isLoading,
        page,
        setPage,
        totalPage,
        pageSize,
        totalItems,
        size = '14px',
        userId
    } = props;
    const {t} = useTranslation('app');
    const {getStockColorValue} = usePreference();

    const column = [
        {
            label: t('openTime'),
            dataKey: 'openTime',
            columProps: {
                flexGrow: 1,
                minWidth: 120,
            },
            CustomCell: MT45TimeTableCell,
        },
        {
            label: t('lastUpdateOn'),
            dataKey: 'lastUpdateOn',
            columProps: {
                flexGrow: 1,
                minWidth: 120,
            },
            CustomCell: MT45TimeTableCell,
        },
        {
            label: t('accountNum'),
            dataKey: 'login',
            columProps: {
                flexGrow: 1,
                minWidth: 120,
            },
        },
        {
            label: t('symbol'),
            dataKey: 'symbol',
            columProps: {
                minWidth: 120,
                flexGrow: 1,
            },
        },
        {
            label: t('closedLots'),
            dataKey: 'lots',
            columProps: {
                flexGrow: 1,
                minWidth: 120,
            },
            renderCell: (rawData) => {
                return numeral(get(rawData, 'volume')).format('0,0.00');
            },
        },
        {
            label: t('openPrice'),
            dataKey: 'openPrice',
            columProps: {
                flexGrow: 1,
                minWidth: 120,
            },
            renderCell: (rawData) => {
                const digits = get(rawData, 'digits');
                return (
                    <Text fontSize={size}>
                        {numeral(get(rawData, 'openPrice')).format(
                            digits
                                ? `0,0.${new Array(digits).fill(0).join('')}`
                                : '0,0.00',
                        )}
                    </Text>
                );
            },
        },
        {
            label: t('closePrice'),
            dataKey: 'closePrice',
            columProps: {
                // align: 'left',
                minWidth: 120,
                flexGrow: 1,
            },
            renderCell: (rawData) => {
                const digits = get(rawData, 'digits');
                return (
                    <Text fontSize={size}>
                        {numeral(get(rawData, 'closePrice')).format(
                            digits
                                ? `0,0.${new Array(digits).fill(0).join('')}`
                                : '0,0.00',
                        )}
                    </Text>
                );
            },
        },
        {
            label: t('CMD'),
            dataKey: 'cmd',
            columProps: {
                width: 70,
            },
            renderCell: (rawData) => {
                return (
                    <Text
                        fontSize={size}
                    >
                        {t(Boolean(get(rawData, 'cmd')) ? 'sell' : 'buy')}
                    </Text>
                );
            },
        },
        {
            label: t('profits'),
            dataKey: 'profit',
            columProps: {
                flexGrow: 1,
                minWidth: 80,
                align: 'right'
            },
            renderCell: (rawData) => {
                return (
                    <Text
                        fontSize={size}
                        color={getStockColorValue(get(rawData, 'profit'))}
                    >
                        {numeral(get(rawData, 'profit')).format('0,0.00')}
                    </Text>
                );
            },
        },
    ];

    return (
        <>
            <CustomTable
                data={data.map((item) => ({
                    ...item,
                }))}
                column={column}
                title={title}
                loading={isLoading}
                page={page}
                setPage={setPage}
                totalPage={totalPage}
                pageSize={pageSize}
                totalItems={totalItems}
                fullPagination
            />
        </>
    );
};